<template>
  <v-row justify="start">
    <v-dialog v-model="dialogCreate" scrollable persistent max-width="700px">
      <v-card class="rounded-max hide_overflow" flat>
        <div class="closer d-flex justify-end pa-1">
          <v-btn icon small @click="$emit('close')">
            <v-icon small>mdi-close</v-icon>
          </v-btn>
        </div>
        <v-card-text class="mx-0">
          <v-container>
            <h3 class="px-5 color_default" v-if="step == 1">Tambah Pengguna</h3>
            <div class="py-3 px-5" v-if="step == 1">
              <v-row>
                <v-col cols="6">
                  <label for="name" class="color_txt">Nama Lengkap</label>
                  <v-text-field
                    v-model="name"
                    color="#00b4cc"
                    dense
                    placeholder="Ketik Disini"
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <label for="name" class="color_txt">No Telepon</label>
                  <v-text-field
                    v-model="number"
                    dense
                    type="number"
                    color="#00b4cc"
                    placeholder="Ketik Disini"
                  ></v-text-field>
                </v-col>
              </v-row>
              <div class="mt-2">
                <b class="color_default">Jenis Akun</b>
                <v-radio-group dense color="info" class="mt-2" v-model="role">
                  <v-radio
                    label="Eksternal"
                    v-if="access_right !== 'Dinkes Provinsi'"
                    value="Eksternal"
                  ></v-radio>
                  <v-radio
                    label="Kemenkes"
                    v-if="access_right !== 'Dinkes Provinsi'"
                    value="Kemenkes"
                  ></v-radio>
                  <v-radio
                    v-if="access_right !== 'Dinkes Provinsi'"
                    label="Dinkes Provinsi"
                    value="Dinkes Provinsi"
                  ></v-radio>
                  <v-radio
                    label="Dinkes Kabupaten/Kota"
                    value="Dinkes Kota"
                  ></v-radio>
                  <v-radio
                    label="Fasyankes (Rumah Sakit/Puskesmas)"
                    value="Fasyankes"
                  ></v-radio>
                </v-radio-group>
              </div>
            </div>
            <div class="py-3 px-5" v-if="step == 2" style="min-height: 300px">
              <div v-if="role == 'Eksternal'">
                <b class="color_default">Eksternal</b>
                <div class="mt-5">
                  <v-row>
                    <v-col cols="6">
                      <label for="name" class="color_txt">Nama Instansi</label>
                      <v-text-field
                        v-model="instansi"
                        color="#00b4cc"
                        dense
                        placeholder="Ketik Disini"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <label for="name" class="color_txt">Jabatan</label>
                      <v-text-field
                        v-model="jabatan"
                        color="#00b4cc"
                        dense
                        placeholder="Ketik Disini"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="6">
                      <label for="name" class="color_txt"
                        >Nama Penanggung Jawab</label
                      >
                      <v-text-field
                        v-model="penanggung_jawab"
                        color="#00b4cc"
                        dense
                        placeholder="Ketik Disini"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <label for="name" class="color_txt">Kepentingan</label>
                      <v-text-field
                        v-model="kepentingan"
                        color="#00b4cc"
                        dense
                        placeholder="Ketik Disini"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </div>
              </div>
              <div v-if="role == 'Kemenkes'">
                <b class="color_default">Kementrian Kesehatan</b>
                <div class="mt-5">
                  <v-row>
                    <v-col cols="6">
                      <label for="name" class="color_txt">Nama Instansi</label>
                      <v-text-field
                        v-model="instansi"
                        color="#00b4cc"
                        dense
                        placeholder="Ketik Disini"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <label for="name" class="color_txt">Jabatan</label>
                      <v-text-field
                        v-model="jabatan"
                        color="#00b4cc"
                        dense
                        placeholder="Ketik Disini"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </div>
              </div>
              <div v-if="role == 'Dinkes Provinsi'">
                <b class="color_default">Dinas Kesehatan Provinsi</b>
                <div class="mt-5">
                  <v-row>
                    <v-col cols="6">
                      <label for="name" class="color_txt">Provinsi</label>
                      <v-select
                        placeholder="pilih Provinsi"
                        item-text="provinsi_name"
                        item-value="provinsi_id"
                        @change="getCity"
                        v-model="objProvince"
                        :items="province"
                        dense
                        hide-details="auto"
                      ></v-select>
                    </v-col>
                    <v-col cols="6">
                      <label for="name" class="color_txt">Nama Instansi</label>
                      <v-text-field
                        hide-details="auto"
                        v-model="instansi"
                        color="#00b4cc"
                        dense
                        placeholder="Ketik Disini"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <label for="name" class="color_txt">Jabatan</label>
                      <v-text-field
                        hide-details="auto"
                        v-model="jabatan"
                        color="#00b4cc"
                        dense
                        placeholder="Ketik Disini"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </div>
              </div>
              <div v-if="role == 'Dinkes Kota'">
                <b class="color_default">Dinas Kesehatan Kabupaten/kota</b>
                <div class="mt-5">
                  <v-row>
                    <v-col cols="6">
                      <label for="name" class="color_txt">Provinsi</label>
                      <v-select
                        placeholder="pilih Provinsi"
                        item-text="provinsi_name"
                        item-value="provinsi_id"
                        @change="getCity"
                        v-model="objProvince"
                        :items="province"
                        dense
                        hide-details="auto"
                      ></v-select>
                    </v-col>
                    <v-col cols="6">
                      <label for="name" class="color_txt">Kabupaten/Kota</label>
                      <v-select
                        placeholder="pilih Kabupaten/kota"
                        item-text="kabupaten_name"
                        item-value="kabupatenKota_id"
                        v-model="selectedCity"
                        :items="city"
                        :disabled="!city"
                        :loading="loadingcity"
                        dense
                        hide-details="auto"
                      ></v-select>
                    </v-col>
                    <v-col cols="6">
                      <label for="name" class="color_txt">Nama Instansi</label>
                      <v-text-field
                        v-model="instansi"
                        hide-details="auto"
                        color="#00b4cc"
                        dense
                        placeholder="Ketik Disini"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <label for="name" class="color_txt">Jabatan</label>
                      <v-text-field
                        hide-details="auto"
                        v-model="jabatan"
                        color="#00b4cc"
                        dense
                        placeholder="Ketik Disini"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </div>
              </div>
              <div v-if="role == 'Fasyankes'">
                <b class="color_default">Fasyankes (Rumah Sakit Puskesmas)</b>
                <div class="mt-5">
                  <v-row>
                    <v-col cols="6">
                      <label for="name" class="color_txt">Provinsi</label>
                      <v-select
                        placeholder="pilih Provinsi"
                        item-text="provinsi_name"
                        item-value="provinsi_id"
                        @change="getCity()"
                        v-model="objProvince"
                        :disabled="access_right == 'Dinkes Provinsi'"
                        :items="province"
                        dense
                        hide-details="auto"
                      ></v-select>
                    </v-col>
                    <v-col cols="6">
                      <label for="name" class="color_txt">Kabupaten/Kota</label>
                      <v-select
                        placeholder="pilih Kabupaten/kota"
                        item-text="kabupaten_name"
                        item-value="kabupatenKota_id"
                        v-model="selectedCity"
                        @change="getInstansi(selectedCity)"
                        :items="city"
                        :loading="loadingcity"
                        :disabled="!city"
                        dense
                        hide-details="auto"
                      ></v-select>
                    </v-col>
                   
                    <v-col cols="6">
                     
                      <div>
                        <v-radio-group
                          v-model="tipeFasyankes"
                          hide-details="auto"
                          :disabled="!selectedCity"
                          class="mt-0"
                          dense
                          row
                        >
                          <v-radio
                            label="Rumah Sakit"
                            value="1"
                            class="small-txt mr-2"
                          ></v-radio>
                          <v-radio
                            label="Puskesmas"
                            class="small-txt"
                            value="2"
                          ></v-radio>
                        </v-radio-group>
                      </div>
                      <div>
                        <div v-if="loading">
                          <v-progress-circular
                            indeterminate
                            class="mr-2"
                            color="white"
                            size="20"
                          ></v-progress-circular>
                          loading...</v-btn
                        >
                        </div>
                        
                        <div v-if="tipeFasyankes == '1' && !loading">
                          <v-select
                            :placeholder="`pilih ${
                              tipeFasyankes == 1 ? 'Rumah Sakit' : 'Puskesmas'
                            }`"
                            dense
                            return-object
                            item-text="nama_rs"
                            v-model="instansi"
                            :items="hospitals"
                            hide-details="auto"
                          ></v-select>
                        </div>
                        <div v-if="tipeFasyankes == '2' && !loading">
                          <v-select
                            :placeholder="`pilih ${
                              tipeFasyankes == 1 ? 'Rumah Sakit' : 'Puskesmas'
                            }`"
                            dense
                            item-text="namaPuskesmas"
                            return-object
                            v-model="instansi"
                            :items="public_health_center"
                            hide-details="auto"
                          ></v-select>
                        </div>
                        
                        
                      </div>
                      
                      <!-- <div>
                        <v-card class="pa-3 mt-2 temp_list" v-if="rs.length">
                          <div
                            class="strip_ pa-2"
                            v-for="(item, idx) in rs"
                            :key="`rs-${idx}`"
                          >
                          {{item.nama_rs}}
                          </div>
                        </v-card>
                      </div> -->
                    </v-col>
                    <v-col cols="6">
                      <label for="name" class="color_txt">Jabatan</label>
                      <v-text-field
                        hide-details="auto"
                        v-model="jabatan"
                        color="#00b4cc"
                        dense
                        placeholder="Ketik Disini"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </div>
              </div>
            </div>
            <div class="py-3 px-5" v-if="step == 3" style="min-height: 300px">
              <b class="color_default">Akun</b>

              <div class="mt-3 mb-5">
                <v-row>
                  <v-col cols="12">
                    <label for="name" class="color_txt">Email</label>
                    <v-text-field
                      v-model="email"
                      @blur="checkForm('email')"
                      color="#00b4cc"
                      dense
                      hide-details="auto"
                      placeholder="Ketik Disini"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <label for="name" class="color_txt">Password</label>
                    <v-text-field
                      v-model="password"
                      color="#00b4cc"
                      hide-details="auto"
                      placeholder="Ketik Disini"
                      dense
                      :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="show1 ? 'text' : 'password'"
                      @click:append="show1 = !show1"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <label for="name" class="color_txt">Ulangi Password</label>
                    <v-text-field
                      v-model="repassword"
                      color="#00b4cc"
                      hide-details="auto"
                      placeholder="Ketik Disini"
                      dense
                      :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="show2 ? 'text' : 'password'"
                      @click:append="show2 = !show2"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </div>
            </div>
          </v-container>
        </v-card-text>

        <v-card-actions class="py-3 px-5">
          <div v-if="step != 1">
            <v-btn
              color="#00b4cc"
              width="100px"
              dark
              outlined
              class="text-capitalize custom_shadow"
              @click="backStep"
              >Kembali</v-btn
            >
          </div>
          <v-spacer></v-spacer>
          <div>
            <v-btn
              v-if="step != 3"
              color="#00b4cc"
              width="100px"
              dark
              class="text-capitalize"
              @click="validateStep"
              >lanjut</v-btn
            >
            <div v-if="step == 3">
              <v-btn
                v-if="!loading"
                color="#00b4cc"
                width="100px"
                dark
                class="text-capitalize"
                @click="createUser"
                >Submit</v-btn
              >
              <v-btn
                v-if="loading"
                color="#00b4cc"
                dark
                class="text-capitalize"
              >
                <v-progress-circular
                  indeterminate
                  class="mr-2"
                  color="white"
                  size="20"
                ></v-progress-circular>
                loading...</v-btn
              >
            </div>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapState } from "vuex";
import Cookies from "js-cookie";
import Swal from "sweetalert2";

export default {
  name: "dialogCreate",
  props: ["dialogCreate"],
  computed: {
    ...mapState({
      err: (state) => state.auth.errMsg,
      solve: (state) => state.auth.response,
      province: (state) => state.region.province,
      city: (state) => state.region.city,
      access_right: (state) => state.role,
    }),
  },
  data() {
    return {
      role: "",
      loading: false,
      loadingcity: false,
      name: "",
      number: "",
      instansi: "",
      provinsi: "",
      selectedProvince: null,
      selectedCity: null,
      objProvince: null,
      kota: "",
      email: "",
      jabatan: "",
      tipeFasyankes: "",
      password: "",
      repassword: "",
      penanggung_jawab: "",
      kepentingan: "",
      step: 1,
      rs: [],
      puskesmas: [],
      show1: false,
      show2: false,
      userProv: null,
      hospitals: [],
      public_health_center: [],
    };
  },
  mounted() {
    if (this.access_right == "Dinkes Provinsi") {
      this.getProfile();
    } else {
      this.getProvince();
    }
  },
  methods: {
    getProvince() {
      this.$store
        .dispatch("region/getProvince")
        .then((data) => {
          data.data.forEach((el) => {
            if (el.provinsi_id == this.userProv) {
              this.objProvince = el;
              this.getCity();
            }
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getProfile() {
      this.loading = true;
      let id = Cookies.get("id");
      this.$store
        .dispatch("user/getProfile", id)
        .then((data) => {
          this.date = this.$date().format("YYYY-MM");
          let user = data.data;
          this.userProv = user.provinsi;
          this.getProvince();
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
          this.Swal("error", err.message);
        });
    },

    getCity() {
      let data = {
        provinsi_id: this.objProvince,
      };
      this.loadingcity = true;
      this.selectedProvince = this.objProvince;
      this.$store
        .dispatch("region/getCity", data)
        .then(() => {
          this.loadingcity = false;
        })
        .catch((err) => {
          console.log(err);
          this.loadingcity = false;
        });
    },
    getInstansi(id) {
      this.getRs(id);
      this.getPuskesmas(id);
    },
    getRs(id) {
      this.loading = true;
      this.$store
        .dispatch("region/getRs", id)
        .then((data) => {
          this.hospitals = Object.values(data.data)
            .filter(item => item.nama_rs !== null)
            .sort((a, b) => a.nama_rs.localeCompare(b.nama_rs))
            .map(item => ({
              nama_rs: item.nama_rs,
              kode_rs: item.kode_rs,
            }));
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    toTitleCase(str) {
      return str.replace(
        /\w\S*/g,
        (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
      );
    },
    getPuskesmas(id) {
      this.loading = true;
      this.$store
        .dispatch("region/getPuskesmas", id)
        .then((data) => {
          this.public_health_center = Object.values(data.data)
          .sort((a, b) => a.namaPuskesmas.localeCompare(b.namaPuskesmas))
          .map((item) => ({
            namaPuskesmas: this.toTitleCase(item.namaPuskesmas),
            kodePuskesmas: item.kodePuskesmas,
          }));
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    createUser() {
      let instance = null;
      let code = null;
      if (this.role == "Fasyankes") {
        if (this.tipeFasyankes == 1) {
          instance = this.instansi.nama_rs ? this.instansi.nama_rs : "";
          code = this.instansi.profilrs_id ? this.instansi.profilrs_id : null;
        } else if (this.tipeFasyankes == 2) {
          instance = this.instansi.namaPuskesmas
            ? this.instansi.namaPuskesmas
            : "";
          code = this.instansi.profilpuskesmas_id
            ? this.instansi.profilpuskesmas_id
            : null;
        }
      }

      let data = {
        access_right: this.role,
        full_name: this.name,
        telpon: this.number ? this.number : "62",
        nama_instansi: this.role == "Fasyankes" ? instance : this.instansi,
        fasyankes_kode: code,
        provinsi:
          this.role === "Kemenkes" || this.role === "Eksternal"
            ? null
            : this.objProvince.provinsi_id ?? this.objProvince,
        jabatan: this.jabatan,
        kota: this.selectedCity,
        usertype: this.tipeFasyankes,
        emails: this.email,
        username: this.email,
        nip: "0",
        password: this.password,
        ulang_password: this.repassword,
        penanggung_jawab: this.penanggung_jawab,
        kepentingan: this.kepentingan,
      };
      this.loading = true;
      this.$store
        .dispatch("user/createUser", data)
        .then((res) => {
          this.loading = false;
          if (res.status !== 226) {
            this.role = "";
            this.name = "";
            this.number = "";
            this.instansi = "";
            this.provinsi = "";
            this.jabatan = "";
            this.kota = "";
            this.email = "";
            this.password = "";
            this.repassword = "";
            this.tipeFasyankes = "";
            this.step = 1;
            this.selectedProvince = null;
            this.selectedCity = null;
            this.penanggung_jawab = "";
            this.kepentingan = "";
            this.$emit("refetch");
            this.Swal("success", res.data.message);
          } else {
            this.Swal("warning", res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
          this.Swal("error", err.message);
        });
    },
    Swal(type, msg) {
      Swal.fire({
        icon: type,
        title: msg,
        showConfirmButton: false,
        position: "top-end",
        timer: 3000,
        toast: true,
        customClass: {
          title: "family",
        },
      });
    },
    checkForm(type) {
      console.log(type);
    },
    validateStep() {
      if (this.step == 1) {
        if (this.name !== "" && this.number !== "" && this.role !== "") {
          this.nextStep();
        } else {
          this.Swal("error", "Lengkapi Form!");
        }
      } else if (this.step == 2) {
        if (this.role == "Kemenkes") {
          if (this.instansi !== "" && this.jabatan !== "") {
            this.nextStep();
          } else {
            this.Swal("error", "Lengkapi Form!");
          }
        } else if (this.role == "Eksternal") {
          if (this.instansi !== "" && this.jabatan !== "") {
            this.nextStep();
          } else {
            this.Swal("error", "Lengkapi Form!");
          }
        } else if (this.role == "Fasyankes") {
          if (this.instansi !== "") {
            this.nextStep();
          } else {
            this.Swal("error", "Lengkapi Form!");
          }
        } else {
          this.nextStep();
        }
      }
    },
    nextStep() {
      this.step++;
    },
    backStep() {
      this.step--;
    },
  },
};
</script>

<style scoped>
.v-label {
  font-size: small;
}
.side_left {
  width: 40%;
  height: 400px;
  border-radius: 0 !important;
}
.side_right {
  width: 60%;
}
.closer {
  width: 100%;
}
.temp_form {
  padding: 20px 25px;
}
.hide_overflow {
  overflow: hidden;
}
.family {
  font-family: Arial, Helvetica, sans-serif;
}
.small-txt {
  font-size: small !important;
}
.strip_ {
  padding: 10px;
  cursor: pointer;
}
.strip_:hover {
  background: #fbfbfb;
}
.temp_list {
  height: 300px;
  overflow: hidden;
  overflow-y: scroll;
}
</style>
